

const Footer = () => {
  return (

    <div style={{ backgroundColor: '#900608', color: 'white', padding: '10px', textAlign: 'center' }} >
      <div className="container">&#169;Copyright, SNSS & Co.</div>
    </div>
  )
}
export default Footer;